import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'
import classnames from 'classnames'
import { Button, Classes, ControlGroup, Intent } from '@blueprintjs/core'

@inject('auth')
class TokenCreateNewContent extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    onCreate: PropTypes.func.isRequired
  }

  state = {
    error: false,
    loading: false,
    name: ''
  }

  focus() {
    this.nameInput.focus()
  }

  componentDidMount() {
    this.focus()
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ error: false, loading: true })
    const { auth } = this.props;

    const name = this.state.name;

    this.setState({ creating: true })
    auth.fetch(
      '/api/tokens',
      {
        method: 'POST',
        body: {
          token: { name }
        }
      })
      .then(response => response.ok ? response.json() : Promise.reject(response))
      .then(data => {
        this.setState({ name: '', loading: false })
        this.props.onCreate(data)
      })
      .catch(error => {
        this.setState({ error: error, loading: false })
        this.focus()
      })
  }

  handleOnChange = (ev) => {
    this.setState({ name: ev.target.value.trim() })
  }

  get isDisabled() {
    return this.state.name.length === 0 || this.state.loading
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <ControlGroup>
            <input
              ref={el => this.nameInput = el}
              onChange={this.handleOnChange}
              value={this.state.name}
              className={classnames(Classes.INPUT, { [Classes.INTENT_DANGER]: this.state.error })}
              type="text"
              placeholder="Name" dir="auto"
            />
            <Button disabled={this.isDisabled} type="submit" icon="add" text="Create" intent={Intent.SUCCESS} />
          </ControlGroup>
        </form>
      </div>
    )
  }
}

export default TokenCreateNewContent