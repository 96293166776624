import React, { Component } from "react";
import { inject } from 'mobx-react';
import { Redirect } from 'react-router'

@inject(({auth}) => ({
  authenticating: auth.authenticating,
  loggedIn:       auth.loggedIn,
}))
class Auth0CallbackPage extends Component {
  render() {
    if(this.props.authenticating) {
      return null
    }

    return <Redirect to={"/"} />;
  }
}

export default Auth0CallbackPage