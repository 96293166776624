import React from 'react'
import OriginalLoadable from 'react-loadable'

import ErrorPage from '../../Containers/StatePage/ErrorPage'
import LoadingPage from '../../Containers/StatePage/LoadingPage'

function Loading(props) {
  if (props.error) {
    return <ErrorPage />
  }

  return <LoadingPage />
}

export default function Loadable(loader, opts) {
  return OriginalLoadable(Object.assign({
    loader,
    loading: Loading,
    timeout: 10,
  }, opts));
};
