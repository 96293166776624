import React from 'react';

const RecoveryLink = ({ onClick, ...props }) => {
  const handleOnClick = ev => {
    ev.preventDefault()
    onClick(ev)
  }

  return <a {...props} href="/" onClick={handleOnClick}>{props.children}</a>
}

export default RecoveryLink
