import React from 'react'
import { inject } from 'mobx-react'
import { Router, Route, Switch } from 'react-router-dom'
import PageContent from "../../Components/PageContent"

import Disclaimer from '../../Components/Disclaimer'
import Loadable from '../../Components/Loadable'
import Layout from '../../Components/Layout'
import NotFoundPage from '../StatePage/NotFoundPage'

import Auth0CallbackPage from '../Auth0CallbackPage'
import DashboardPage from '../DashboardPage'
import HomePage from '../HomePage'
import SearchPage from '../SearchPage'
import ContentPage from '../ContentPage'
import TokensPage from '../TokensPage'

const ShowPage = Loadable(() => import(/* webpackChunkName: "show-page" */ '../ShowPage'))

const RootPage      = inject(['auth'])(({ auth }) => auth.loggedIn ? <DashboardPage /> : <HomePage />)
const RouteNotFound = <Route path="*" component={NotFoundPage} />

const App = ({ history }) => (
  <Router history={history}>
    <Layout>
      <Switch>
        {/* Root */}
        <Route exact path="/" component={RootPage} />

        {/* Utils */}
        <Route exact path="/auth0/:any" component={Auth0CallbackPage} />
        <Route path="/reload" render={() => null} />

        {/* Content */}
        <Route path='/:any(.*)?' render={
          () => (
            <PageContent>
              <Switch>
                {/* This nested route make sure that /app prefix wont be mistaken with /:slug */}
                <Route path='/app' render={
                  () => (
                    <Switch>
                      <Route exact path="/app/search" component={SearchPage} />
                      <Route exact path="/app/tokens" component={TokensPage} />
                      {RouteNotFound}
                    </Switch>
                  )
                } />
                <Route path="/legal/disclaimer" render={() => <ContentPage type="Disclaimer" />} />
                <Route path="/legal/privacy" render={() => <ContentPage type="Privacy"/>} />
                <Route path="/legal/terms" render={() => <ContentPage type="Terms"/>} />
                <Route path="/how-it-works" render={() => <ContentPage type="HowItWorks"/>} />
                <Route exact path="/:path(.*)?" component={ShowPage} />
              </Switch>
            </PageContent>
          )
        } />

        {RouteNotFound}
      </Switch>
      <Disclaimer />
    </Layout>
  </Router>
)

export default App
