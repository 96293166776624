import React, { Component } from "react";
import { inject } from "mobx-react";
import { Button, Intent, Popover, Position } from "@blueprintjs/core";
import PropTypes from "prop-types";
import Title from "react-document-title";
import GenericList from "../../Components/GenericList";
import TokenCreateNewContent from "./CreateNewContent";

import { TokensUsage, CLIIntro } from "../../Components/UsageTips";

// import StatePage from '../../Containers/StatePage'
// import PageHeader from '../../Components/PageHeader'

import "./TokensPage.css";

@inject("auth")
class TokensPage extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  state = {
    tokens: [],
    newIsOpen: false
  };

  componentDidMount() {
    this.props.auth
      .fetch("/api/tokens")
      .then(response => response.json())
      .then(tokens => {
        this.setState({ tokens });
      });
  }

  handleOpenNew = () => {
    this.setState({ newIsOpen: true });
  };

  handleCloseNew = () => {
    this.setState({ newIsOpen: false });
  };

  handleOnCreate = token => {
    this.setState(({ tokens }) => ({
      newIsOpen: false,
      tokens: [...tokens, token]
    }));
  };

  handleDestroyToken = (ev, token) => {
    ev.preventDefault();
    this.setState(({ tokens }) => ({
      tokens: tokens.filter(t => t !== token)
    }));

    this.props.auth.fetch(`/api/tokens/${token.id}`, { method: "DELETE" });
  };

  render() {
    const title = "API Tokens";
    return (
      <React.Fragment>
        <Title title={title}>
          <GenericList
            controls={this.renderNew()}
            title={title}
            items={this.state.tokens}
            itemRenderer={this.renderItem}
            itemControlsRenderer={this.renderItemControl}
          />
        </Title>
        <div className="usageView">
          <TokensUsage />
          <CLIIntro />
        </div>        
      </React.Fragment>
    );
  }

  renderItem(token) {
    return (
      <React.Fragment>
        <h4>{token.name}</h4>
        <pre>{token.token}</pre>
      </React.Fragment>
    );
  }

  renderItemControl = token => {
    return (
      <Button
        icon="delete"
        text={"Revoke"}
        intent={Intent.DANGER}
        onClick={ev => this.handleDestroyToken(ev, token)}
      />
    );
  };

  renderNew() {
    return (
      <Popover
        content={<TokenCreateNewContent onCreate={this.handleOnCreate} />}
        target={
          <Button
            icon="add"
            text={"Add token"}
            intent={Intent.PRIMARY}
            onClick={this.handleOpenNew}
            className="layersCreateButton"
          />
        }
        popoverClassName="pt-popover-content-sizing"
        position={Position.BOTTOM}
        isOpen={this.state.newIsOpen}
        onClose={this.handleCloseNew}
      />
    );
  }
}

export default TokensPage;
