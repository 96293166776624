import Auth0 from 'auth0-js'

export default function connect({ clientId: clientID, domain }) {
  return new Auth0.WebAuth({
    domain,
    clientID,
    responseType: 'id_token token',
    scope: 'openid profile email',
    redirectUri: `${window.location.origin}/auth0/callback`
  });
}
