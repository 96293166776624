import React from 'react'
import { Flex, Box } from 'reflexbox'
import SearchField from '../SearchField'

import './PageHeader.css'

function PageHeader({ title }) {
  return (
    <Flex className="page-header" justify="space-between" align="center">
      <Box>
        <h1>{title}</h1>
      </Box>
      <Box>
        <SearchField />
      </Box>
    </Flex>
  )
}

export default PageHeader
