import 'react-app-polyfill/ie11';
import 'core-js/modules/es6.set';
import 'core-js/modules/es6.map';
import 'core-js/modules/es7.array.includes';
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import 'whatwg-fetch'
import history from 'lib/history'
import { initializeGA, setUser, trackPageview } from 'lib/ga'
import { autorun } from 'mobx';

// NOTE: temporary disabled, because it caused many confusions
// import registerServiceWorker from './registerServiceWorker'
// import { unregister } from './registerServiceWorker';

import App from './Containers/App'
import AuthStore from './Store/Auth'
import BaseStore from './Store/Base'

// Global styles
import 'normalize.css/normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import './index.css'

function initApp () {
  const auth = new AuthStore({
    auth0Credentials: {
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      domain: process.env.REACT_APP_AUTH0_DOMAIN
    },
    history
  })

  const getCurrentPath = () => {
    const { pathname, search } = history.location
    return `${pathname}${search}`
  }


  history.listen(() => trackPageview(getCurrentPath()))
  initializeGA()
  autorun(() => setUser(auth.user))
  trackPageview(getCurrentPath())

  const base = new BaseStore({ fetch: auth.fetch, history })

  ReactDOM.render(
    <Provider auth={auth} base={base}>
      <App history={history} />
    </Provider>
    ,
    document.getElementById('root')
  );
}

initApp()

// registerServiceWorker();
// unregister();
