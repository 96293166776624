import defaultFetch from 'unfetch'
import { action, autorun, computed, observable, reaction, runInAction } from 'mobx';
import { Intent } from "@blueprintjs/core";
import Notifications from '../lib/notifications'
import auth0 from '../lib/auth0'

const urlBase = process.env.REACT_APP_API_BASE || process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : ''

const ensureApiUrl = (url) => {
  if (urlBase && !url.match(/^https?:\/\//)) {
    return urlBase + url
  }

  return url
}

export default class AuthStore {
  originalFetch = null

  @observable authenticating = false
  @observable authToken = null
  @observable user = null

  constructor({
    auth0Credentials,
    history,
    fetch = defaultFetch
  }) {
    this.auth0Credentials = auth0Credentials
    this.history = history
    this.originalFetch = fetch

    syncWithLocalStorage(this)
    const { pathname, search } = history.location

    if(pathname === '/auth0/callback') {
      this.authenticating = true
      this.handleAuthentication()
    } else if(pathname === '/auth0/fake' && search.includes('email=')) {
      this.authenticating = true
      this.handleFakeAuthentication(search.match(/email=([^\&]+)/)[1])
    } else if(search.includes('auth=signup')) {
      this.showSignUp()
    } else if(search.includes('auth=login')) {
      this.showLogin()
    }

    reaction(
      () => this.loggedIn,
      loggedIn => history.push(loggedIn ? `/${this.user.username}` : "/")
    )

    reaction(
      () => this.loggedIn,
      loggedIn => {
        if (window.Rollbar) {
          if (loggedIn) {

            window.Rollbar.configure({
              payload: {
                person: {
                  id: this.user.id,
                  email: this.user.email,
                  username: this.user.username
                }
              }
            });
          } else {
            window.Rollbar.configure({
              payload: {
                person: null
              }
            });
          }
        }
      },
      {
        fireImmediately: true
      }
    )
  }

  async getAuth0() {
    if(!this.auth0) {
      this.auth0 = auth0(this.auth0Credentials)
    }

    return this.auth0
  }

  fetch = (url, { headers, body, ...options } = {}) => {
    const authHeader = {}

    if (this.authToken) {
      authHeader['Authorization'] = 'Bearer ' + this.authToken
    }

    return this.originalFetch(ensureApiUrl(url), {
      ...options,
      body: typeof(body) === "object" ? JSON.stringify(body) : body,
      headers: {
        ...headers,
        ...authHeader,
        'Accept': 'application/vnd.restful+json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (!response.ok && response.status === 401) {
        // TODO: improve this workflow. especialy expiring of token
        this.authorizationFailed()
        return Promise.reject(response)
      } else {
        return response
      }
    })
  }

  authorizationFailed() {
    this.logout()
    Notifications.danger({
      message: 'You have been logged out, login again.',
      timeout: 60 * 1000,
      action: {
        intent: Intent.PRIMARY,
        text: 'Login',
        onClick: this.showLogin
      }
    })
  }

  showLogin = () => {
    this.getAuth0().then(auth0 => auth0.authorize())
  }

  showSignUp = () => {
    this.getAuth0().then(auth0 => auth0.authorize())
  }

  async handleAuthentication() {
    const auth0 = await this.getAuth0()
    auth0.parseHash(async (err, authResult) => {
      if (err) {
        await this.handleAuthenticatedError(err)
      } else {
        await this.handleAuthenticated(authResult)
      }
    });
  }

  async handleFakeAuthentication(email) {
    console.log("Handling fake auth")

    try {
      const response = await supermodelFakeAuthenticate(this, email)
      const { user, auth_token: authToken } = await response.json()

      runInAction(() => {
        this.authenticating = false
        this.authToken = authToken
        this.user = user
      })
    } catch(error) {
      this.handleAuthenticatedError(error)
    }
  }

  logout = () => {
    this.authToken = null
    this.user = null
  }

  @computed
  get loggedIn() {
    return this.authToken !== null && this.user !== null
  }

  @computed
  get showDisclaimer() {
    return this.user && !this.user.disclaimer_accepted_at
  }

  acceptDisclaimer = async (permanent = false) => {
    const now = new Date().toISOString()

    if (permanent) {
      const response = await this.fetch(`/api/user`, {
        method: 'PATCH',
        body: {
          user: {
            disclaimer_accepted_at: now
          }
        }
      })

      const user = await response.json()

      Object.assign(this.user, user)
    } else {
      this.user.disclaimer_accepted_at = now
    }
  }

  @action
  handleAuthenticated = async (auth0Result) => {
    try {
      const response = await supermodelAuthenticate(this, auth0Result.idToken)
      const { user, auth_token: authToken } = await response.json()

      runInAction(() => {
        this.authenticating = false
        this.authToken = authToken
        this.user = user
      })
    } catch(error) {
      this.handleAuthenticatedError(error)
    }
  }

  @action
  handleAuthenticatedError(error) {
    console.error("Authentication error", error)
    // TODO: handle error with some message to user or something?
    this.authenticating = false
    this.authToken = null
    this.user = null
  }
}

function syncWithLocalStorage(store) {
  // Load values from local storage and store each change
  store.authToken = localStorage.getItem('authToken')
  const userJSON = localStorage.getItem('user')
  store.user = userJSON ? JSON.parse(userJSON) : null

  autorun(() =>
    store.authToken
      ? localStorage.setItem('authToken', store.authToken)
      : localStorage.removeItem('authToken')
  )
  autorun(() => {
    store.user
      ? localStorage.setItem('user', JSON.stringify(store.user))
      : localStorage.removeItem('user')
  })
}

function supermodelAuthenticate(store, idToken) {
  return store.originalFetch(ensureApiUrl(`/auth0?client_id=${process.env['REACT_APP_CLIENT_ID']}`), {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + idToken,
      'Accept': 'application/vnd.restful+json',
      'Content-Type': 'application/json'
    }
  })
}

function supermodelFakeAuthenticate(store, email) {
  return store.originalFetch(ensureApiUrl(`/auth0/fake?client_id=${process.env['REACT_APP_CLIENT_ID']}&email=${email}`), {
    method: 'GET',
    headers: {
      'Accept': 'application/vnd.restful+json',
      'Content-Type': 'application/json'
    }
  })
}
