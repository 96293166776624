import { action, computed, observable } from 'mobx';
import { yamlModel, validateMetaSchema } from '@supermodel/lib'

import Breadcrumb from './Breadcrumb'
import Notifications from '../lib/notifications'

export default class Entity {
  @observable schema = null
  @observable originalSchema = null
  @observable nested_entities = null
  @observable title = null
  @observable description = null
  @observable parents = null
  @observable schemaError = null
  @observable permissions = null
  url = null
  type = null

  fetch = null

  constructor(data, fetch) {
    Object.assign(this, data)
    this.fetch = fetch
    this.originalSchema = data.schema
  }

  @computed
  get breadcrumbs() {
    return [
      ...this.parents.reverse(),
      { title: this.title, type: this.type, url: this.url }
    ].map(item => new Breadcrumb(item, this.fetch))
  }

  @action
  setSchema(schema) {
    this.schema = schema
    this.schemaError = null

    try {
      const data = yamlModel.readYAML(this.schema)
      validateMetaSchema(data)
    } catch(e) {
      this.schemaError = e.message
    }
  }

  @computed
  get isSchemaChanged() {
    return this.schema !== this.originalSchema
  }

  @computed
  get isSchemaValid() {
    return !this.isSchemaChanged || this.schemaError === null
  }

  @computed
  get canSaveSchema() {
    return this.isSchemaChanged && this.isSchemaValid
  }

  @computed
  get nestedLayers() {
    if (!this.nested_entities) {
      return []
    }

    return this.nested_entities.filter(item => item.type === 'Layer')
  }

  @computed
  get nestedModels() {
    if (!this.nested_entities) {
      return []
    }

    return this.nested_entities.filter(item => item.type === 'Model')
  }

  @computed
  get canCreate() {
    return this.permissions.includes('create')
  }

  @computed
  get canDestroy() {
    return this.permissions.includes('destroy')
  }

  @computed
  get canUpdate() {
    return this.permissions.includes('update')
  }

  @computed
  get canCreateOrUpdate() {
    return this.canCreate || this.canUpdate
  }

  deleteNestedEntity(nestedEntity) {
    this.fetch(nestedEntity.url, { method: 'DELETE' })
      .then(response => {
        if (response.ok) {
          Notifications.success(`${nestedEntity.type} '${nestedEntity.title}' deleted`)
          this.nested_entities.remove(nestedEntity)
        } else {
          throw new Error('failed')
        }
      }).catch(() => Notifications.danger(`${nestedEntity.type} '${nestedEntity.title}' deleting failed`))
  }

  saveSchema() {
    this.fetch(this.url, {
      method: 'PATCH',
      body: { entity: { schema: this.schema }}
    }).then(response => {
      if (response.ok) {
        return response.json()
      }

      return response.json().then(data => Promise.reject(data))
    }).then(entity => {
      Object.assign(this, entity)
      this.originalSchema = entity.schema
      Notifications.success('Model saved')
    }).catch(error => {
      console.error(error)
      let message = 'Model saving failed'
      const errorTitle = error.title || error.error || error.message

      if (errorTitle) {
        message = `${message}: ${errorTitle}`
      }

      Notifications.danger(message)
    })
  }
}
