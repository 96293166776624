import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button, Classes, Menu, MenuItem, MenuDivider, Navbar, NavbarGroup, NavbarHeading, Popover, Position } from '@blueprintjs/core';
import { withRouter } from 'react-router'

import Link from '../Link';
import './NavBar.css'
import badge from './badge.svg';

@withRouter
@inject("auth")
@observer
class NavBar extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  handleNavigate = (ev) => {
    ev.preventDefault()
    const link = ev.target.parentNode

    this.props.history.push({
      pathname: link.pathname
    })
  }

  render() {
    return (
      <Navbar>
        <div className="container">
          <NavbarGroup align="left">
            <NavbarHeading>
              <Link className="navbar-link" to="/">
                <img src={badge} height="35em" alt="badge" />
              </Link>
            </NavbarHeading>
          </NavbarGroup>
          <NavbarGroup align="right">
            { this.renderLogin() }
          </NavbarGroup>
        </div>
      </Navbar>
    );
  }

  renderLogin() {
    const auth = this.props.auth;

    if (auth.loggedIn) {
      return <React.Fragment>
        <NavbarHeading>{auth.user.name}</NavbarHeading>
        <Popover content={this.renderMenu()} position={Position.BOTTOM}>
          <Button
            icon="menu"
            className={Classes.MINIMAL}
          />
        </Popover>
      </React.Fragment>
    }

    return <Button onClick={auth.showLogin}>Login</Button>
  }

  renderMenu() {
    const auth = this.props.auth;

    return <Menu>
      <MenuItem 
        icon="key"
        text="Tokens" 
        href="/app/tokens" 
        onClick={this.handleNavigate} />
      <MenuDivider />
      <MenuItem
        icon="log-out"
        onClick={auth.logout}
        text="Logout"
      />
    </Menu>
  }
}

export default NavBar
