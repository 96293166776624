import { action, observable } from 'mobx';
import { fromPromise } from 'mobx-utils';
import Entity from './Entity'
import Notifications from '../lib/notifications'
import { stripHost } from 'lib/url'

export default class BaseStore {
  @observable entityPromise
  entityPath = null
  searchQuery = null
  fetch = null
  entityForNeighbours = null

  constructor({ fetch, history }) {
    this.fetch = fetch
    this.history = history
  }

  @action
  loadEntity = (path) => {
    if (this.entityPath === path) {
      return
    }

    this.entityPath = path
    const request = this.fetch(`${path}?refs_depth=2`).then(response => {
      if (response.ok) {
        return response.json()
      }

      switch (response.status) {
        case 403:
          return Promise.reject('forbidden')
        case 404:
          return Promise.reject('notFound')
        default:
          return Promise.reject('error')
      }
    }).then(data => new Entity(data, this.fetch))

    this.entityPromise = fromPromise(request)
  }

  createEntity = (path, entityPayload) => {
    return this.fetch(path, {
      method: 'POST',
      body: JSON.stringify({ entity: entityPayload })
    })
    .then(response => {
      if(!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    })
    .then(entity => {
      Notifications.success(`${entity.type} '${entity.title}' created`)
      this.history.push(stripHost(entity.url))
    })
  }
}
