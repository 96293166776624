import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'reflexbox'
import { observer } from 'mobx-react'
import ContentBox from '../ContentBox'

@observer class EntityList extends Component {
  static propTypes = {
    controls: PropTypes.node,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    itemRenderer: PropTypes.func,
    itemControlsRenderer: PropTypes.func,
    title: PropTypes.string.isRequired
  }

  render() {
    const { controls, items, title } = this.props

    return (
      <ContentBox title={title} controls={typeof controls === 'function' ? controls() : controls}>
        {items.map(this.renderItem)}
      </ContentBox>
    )
  }

  renderItem = (item) => {
    const key = item.id || item.url || item.name || item.label;

    return <Flex justify="space-between" key={key} pl={20} pr={20} pt={10} pb={10} align="center">
      <Box>{this.props.itemRenderer(item)}</Box>
      { this.props.itemControlsRenderer && <Box>{this.props.itemControlsRenderer(item)}</Box> }
    </Flex>
  }
}

export default EntityList