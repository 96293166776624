import React from 'react';
import cx from 'classnames';
import { isExternalUrl, stripHost } from 'lib/url';
import history from '../../lib/history';

import './style.css'

const Button = ({
  children,
  className: origClassName = null,
  green = false,
  href = null,
  purple = false,
  to = null,
  ...props
}) => {
  const className = cx(origClassName, 'Button', {
    'Button--green': green,
    'Button--purple': purple
  })

  props.role = "button"

  const text = <span className={'Button__text'}>{children}</span>
  if (to || href) {
    let handleClick

    if (href || isExternalUrl(to)) {
      handleClick = props.onClick
    } else {
      handleClick = ev => {
        props.onClick && props.onClick(ev)

        if (!ev.isDefaultPrevented()) {
          ev.preventDefault()
          history.push(stripHost(to))
        }
      }
    }

    return <a href={to || href} className={className} onClick={handleClick}>
      {text}
    </a>
  }

  return <button className={className} {...props}>
    {text}
  </button>
}

export default Button