import GA from 'react-ga';
let initialized = false

const hasGA = () => {
  return process.env.REACT_APP_GA && process.env.REACT_APP_GA.length
}

const initializeGA = () => {
  if (!initialized) {
    initialized = true;

    withGA(
      ga => ga.initialize(process.env.REACT_APP_GA),
      () => console.log(`Initialize GA`)
    )
  }
}

const setUser = (user) => {
  withGA(
    ga => user ? ga.set({userId: user.id}) : ga.set({userId: null}),
    () => console.log(user ? `Set GA user to ${user.id}` : `Set GA user to null`)
  )
}

const trackPageview = (path) => {
  withGA(
    ga => ga.pageview(path),
    () => console.log('Pageview', path)
  )
}

const withGA = (callback, fallback) => {
  if (hasGA()) {
    callback(GA)
  } else {
    fallback()
  }
}

export {
  initializeGA,
  setUser,
  trackPageview,
  withGA,
}