import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Checkbox, Dialog, Intent } from '@blueprintjs/core';

import Link from '../Link';

import './Disclaimer.css'

@inject("auth")
@observer
class Disclaimer extends Component {
  state = {
    accepted: false,
    loading: false
  }

  submitDisclaimer = async () => {
    this.setState({ loading: true })
    await this.props.auth.acceptDisclaimer(this.state.accepted)
    this.setState({ accepted:false, loading: false })
  }

  toggleAccept = (ev) => {
    this.setState(state => ({ accepted: !state.accepted }))
  }

  render() {
    const auth = this.props.auth;

    if (!auth.showDisclaimer) {
      return null
    }

    return (
      <Dialog
        isOpen={true}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        title="Welcome to Supermodel!"
      >
        <div className="pt-dialog-body Disclaimer">
          <p>
            With Supermodel you can create, host and reference JSON Schema data models. Search for the existing models, reuse thousands of already defined structures or define entirely new domains that suits your needs. Learn more about how to work with Supermodel in <Link to="/how-it-works">how Supermodel works</Link> section.
          </p>
          <p>
            To promote collaboration, reuse, and discovery, and to stop reinventing the wheel all models you create will be public and can be discovered and referenced by other Supermodel users. If you need to make your model private, please contact us.
          </p>
          <p>
            Happy modeling!
          </p>
        </div>
        <div className="pt-dialog-footer">
            <div className="pt-dialog-footer-actions Disclaimer__actions">
                <Checkbox
                  label="Do not show again"
                  onChange={this.toggleAccept}
                  checked={this.state.accepted}
                />
                <Button
                  intent={Intent.PRIMARY}
                  onClick={this.submitDisclaimer}
                  text={this.state.loading ? "Saving..." : "OK"}
                />
            </div>
        </div>
      </Dialog>
    )
  }
}


export default Disclaimer