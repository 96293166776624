import qs from 'querystring'
import history from './history'

// NOTE: stolen from https://github.com/mrded/is-url-external/blob/master/index.js
const HOST = window.location.hostname;
const ABSOLUTE_URL_REGEXP = /^https?:\/\//
const HOST_REGEXP = /^.*\/\/[^/]+/
const PROPERTY_NAME_REGEXP = /#\/properties\/(.*)/
const VALID_HOSTNAME_ALIASES = ['supermodel.io', 'www.supermodel.io', 'localhost']

export const getLinkHost = function(url) {
  if (ABSOLUTE_URL_REGEXP.test(url)) { // Absolute URL.
    // The easy way to parse an URL, is to create <a> element.
    // @see: https://gist.github.com/jlong/2428561
    const parser = document.createElement('a');
    parser.href = url;

    return parser.hostname;
  }
  else { // Relative URL.
    return window.location.hostname;
  }
}

export const isExternalUrl = function(url) {
  const linkHost = getLinkHost(url)
  return HOST !== linkHost;
}

export const isAnySupermodelUrl = function(url) {
  const linkHost = getLinkHost(url)

  return VALID_HOSTNAME_ALIASES.includes(linkHost)
}

export const stripHost = function(url) {
  return url.replace(HOST_REGEXP, '')
}

/**
 * Retrieve the name of the Model property (if any) from url
 * @param {URL} urlObject URL to be processed
 * @return {string, undefined} name of the property if present, undefined otherwise
 */
export const propertyNameFromURL = function(urlObject) {
  if (urlObject.hash) {
    const re = urlObject.hash.match(PROPERTY_NAME_REGEXP)
    if (re && re.length > 1) {
      return re[1]
    }
  }

  return undefined
}

/**
 * Retrieve name of the model from URL
 * @param {URL} urlObject URL to be processed
 * @return {string, undefined} name of the Model from URL
 */
export const modelNameFromURL = function(urlObject) {
  if (!urlObject || !urlObject.pathname) {
    return '- missing name -'
  }

  return urlObject.pathname.substring(urlObject.pathname.lastIndexOf('/') + 1)
}

/**
 * Normalizes URL by removing empty hash and any trailing separators
 *
 * @param {string} url URL to be normalized
 * @returns {string} Normalized URL
 */
export const normalizeURL = function(url) {
  try {
    let normalizedURL = new URL(url)
    if (normalizedURL.hash === "#" || normalizedURL.hash === "#/" )
      normalizedURL.hash = ""

    return normalizedURL.toString()
  }
  catch (error) {
    console.error(`Error normalizing URL '${url}':\n${error}`)
  }
  return url

}

export const navigateTo = function(url, queryParams) {
  // TODO: query params to external URL
  if (isExternalUrl(url)) {
    window.location = url
  }

  const validParams = {}
  Object.keys(queryParams).forEach(key => {
    if (queryParams[key]) {
      validParams[key] = queryParams[key]
    }
  })

  history.push({
    pathname: stripHost(url),
    search: qs.stringify(validParams)
  })
}