import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Title from 'react-document-title'
import { computed, observable } from 'mobx';
import { fromPromise } from 'mobx-utils';
import { inject, observer } from 'mobx-react';
import qs from 'querystring'

import EntityList from '../../Components/EntityList'
import StatePage from '../../Containers/StatePage'
import PageHeader from '../../Components/PageHeader'

import '../../Components/Layer/Layers.css'

@inject('auth')
@observer
class SearchPage extends Component {
  @observable searchPromise = null

  static propTypes = {
    auth: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  }

  @computed
  get userUrl() {
    // TODO: user url should be from user api
    return this.props.auth.user && `${window.location.origin}${this.props.auth.user.url}/`
  }

  @computed
  get ownEntities() {
    if (!this.searchPromise || !(this.searchPromise.state === 'fulfilled')) {
      return null
    }

    return this.searchPromise.value.filter(entity => entity.url.startsWith(this.userUrl))
  }

  @computed
  get restEntities() {
    if (!this.searchPromise || !(this.searchPromise.state === 'fulfilled')) {
      return null
    }

    return this.searchPromise.value.filter(entity => !entity.url.startsWith(this.userUrl))
  }

  componentWillMount() {
    this.query = this.getQuery(this.props)
    this.searchEntities(this.query)
  }

  componentWillReceiveProps(nextProps) {
    this.query = this.getQuery(nextProps)
    this.searchEntities(this.query)
  }

  getQuery(props) {
    const queryParams = qs.parse(props.location.search.slice(1))
    return queryParams.q
  }

  searchEntities(q) {
    if (this.searchQuery === q) {
      return
    }

    this.searchQuery = q
    const request = this.props.auth.fetch(`/api/search?q=${q}`).then(response => {
      if (response.ok) {
        return response.json()
      }

      switch (response.status) {
        case 403:
          return Promise.reject('forbidden')
        case 404:
          return Promise.reject('notFound')
        default:
          return Promise.reject('error')
      }
    })

    this.searchPromise = fromPromise(request)
  }

  render() {
    return this.searchPromise.case({
      pending: () => <StatePage type='loading' />,
      rejected: error => <StatePage type={error} />,
      fulfilled: () => this.renderEntities()
    })
  }

  renderEntities() {
    const entityLists = []
    if(this.userUrl) {
      entityLists.push(<EntityList key="own" title={`My Models & Layers`} items={this.ownEntities} showItemId />)
      entityLists.push(<EntityList key="rest" title="Others" items={this.restEntities} showItemId />)
    } else {
      entityLists.push(<EntityList key="all" title="Results" items={this.searchPromise.value} showItemId />)
    }

    return (
      <Title title={`Search '${this.query}' - Supermodel`}>
        <React.Fragment>
          <PageHeader title={`Search Results for '${this.query}'`} />
          {entityLists}
        </React.Fragment>
      </Title>
    )
  }
}

export default SearchPage