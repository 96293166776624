import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Button from '../../Components/Button'
import SearchField from '../../Components/SearchField'

import './style.css';

import largeLogoImg from './assets/largelogo.svg'
import logoImg from './assets/logo.svg'
import complexityImg from './assets/complexity.svg'
import knowledgeImg from './assets/knowledge.svg'
import modelsImg from './assets/models.svg'
import reuseImg from './assets/reuse.svg'
import meaningImg from './assets/meaning.svg'
import schemaorgImg from './assets/schemaorg.svg'
import gs1Img from './assets/gs1.svg'
import connectImg from './assets/connect.svg'
import jsonSchemaImg from './assets/jsonschema.svg'
import jsonLDImg from './assets/jsonld.svg'
import graphQLImg from './assets/graphql.svg'
import openApiImg from './assets/openapi.svg'
import avroImg from './assets/avro.svg'
import supermodelDrivenImg from './assets/supermodel-driven.svg'

const ExplainBox = ({img, title, subtitle, content}) => {
  return <div className="hp-explain-box">
    <img src={img} alt={title} />
    <h3>{title}</h3>
    <h4>{subtitle}</h4>
    <p>
      {content}
    </p>
  </div>
}

const MeaningBox = ({img, to, title}) => {
  return <div className='meaning-box'>
    <img src={img} alt={title} style={{marginBottom: '0.8rem'}}/>
    <Button purple to={to}>{title} Models</Button>
  </div>
}

const Separator = ({ label = null}) => {
  return <div className='separator'>
    <hr />
    { label && <span>{label}</span>}
  </div>
}

const Container = ({ children, className, ...props }) => {
  return <div className={className} {...props}>
    <div className="container">
      {children}
    </div>
  </div>
}

@inject("auth")
@observer
class HomePage extends Component {
  render() {
    const auth = this.props.auth;

    return <div className="hp">
      <Container className="hp-promo">
        <img className="hp-logo-large" src={largeLogoImg} alt="Large Supermodel Logo" />
        <img className="hp-logo" src={logoImg} alt="Small Supermodel Logo" />
        <h1 className="hp-title">super<strong>model</strong></h1>
        <h2 className="hp-subtitle">The Data Model of Your Domain, API and Application. Solved.</h2>
        <p>
          Supermodel helps your teams get to talking about their domains—as complex as they may be—so you can build software that matters.
          {/* The model of your Organization, Domain, and Application. Solved */}
          {/* Collaborative data modeling that works in the real world. And in the world of AI too. */}
        </p>
        <div className="hp-buttons">
          <Button purple href="/#learn-more">Learn More</Button>
          <Button green onClick={auth.showSignUp}>Sign up</Button>
        </div>

        {/* <Button purple to="/schemaorg/Action?view=graph" className="hp-promo__action">Example</Button> */}
      </Container>
      <Container className="hp-explain" id="learn-more">
        <div className="hp-explain-lead">
          <img className="supermodel-driven" src={supermodelDrivenImg} alt="Supermodel-driven" />
          <Separator />
          <h2>
            Collaborative Data Modeling that Works in the Real World. And in the World of AI too.
          </h2>
          <Separator />
          {/* <p>
          All software projects run into the same fundamental problems. Implicit data model, inferred semantics, lack of domain documentation, poor communication, and tribal knowledge. 
          </p>           */}
        </div>
        <div className="hp-explain-boxes">
          <ExplainBox
            img={modelsImg}
            title={'Model Everything'}
            subtitle={'Your Domain, Application, API or Database'}
            content={'Design-first conceptual and physical modeling that integrates with your development workflow. Define and manage any data using JSON Schema.'}
          />
          <ExplainBox
            img={complexityImg}
            title={'Manage Complexity'}
            subtitle={'Divide and Conquer with Reusable Models'}
            content={'Break larger models into sub-models and handle even the most complex domains with ease. Create reusable models, domains and sub-domains that you can reference everywhere.'}
          />
          <ExplainBox
            img={knowledgeImg}
            title={'Share Knowledge'}
            subtitle={'Discuss and Use Models the Way You Want'}
            content={'API, CLI or website; with Supermodel, you can discuss, share and use models any way you want. OpenAPI or GraphQL schema? Supermodel got you covered.'}
          />
        </div>
      </Container>
      <Container className="hp-search">
        <img src={reuseImg} alt="Magnifying glass icon"/>
        <h3>Reuse Models</h3>
        <h4>Stop Reinventing the Wheel</h4>
        <div className="hp-search__form">
          <SearchField showSubmit={true} large />
        </div>
        <p>
          There is no need to reinvent common data structures. Focus on your core models and reuse supporting domains that you do not need to reinvent.
Defining an address structure that would work both in Thailand and U.S.?
        </p>
        <p>
          Search & start reusing models with Supermodel! 
        </p>
      </Container>
      <Container className="hp-meaning">
        <img src={connectImg} alt="Handshake icon"/>
        <h3>Give Meaning to Data</h3>
        <h4>Make Data Accessible to both Humans and Machines</h4>
        <p>
          Meaning of the data is critical for machine learning and AI. Stop guessing semantics of the fields by looking at the JSON responses. Understanding is the future.
        </p>
        <p>
          The power of the most popular structured data vocabularies Schema.org and GS1 is now available as reusable data models. 
        </p>
        <div className="hp-meaning-boxes">
          <MeaningBox img={schemaorgImg} to={'/schemaorg'} title={'Schema.org'} />
          <MeaningBox img={gs1Img} to={'/gs1'} title={'GS1'} />
        </div>
 
      </Container>
      <Container>
        <Separator />
      </Container>
      <Container className="hp-graph">
        <img src={meaningImg} alt="Man thinking icon" style={{marginBottom: '1rem'}}/>
        <h3>Attain Understanding, Visibility, and Governance</h3>
        <h4>Control your Domain and sub-domains</h4>
        <p>
          The canonical data model is a thing of a past. Define boundaries, empower your teams, work in contexts. Supermodel is the knowledge of your domain and its context-bounded subdomains. 
        </p>
        <p>
          Allow for teams to work fast and independently yet gain insights into their data models.          
        </p>
        <div className="hp-buttons">
          <Button purple to="/schemaorg/Action?view=graph">Example</Button>
          <Button purple to="/how-it-works">How Supermodel Works</Button>
        </div>
      </Container>
      <Container>
        <Separator label={'supermodel works with these formats'} />
      </Container>
      <Container className="hp-formats">
        <a href="https://json-schema.org/" title="JSON Schema">
          <img src={jsonSchemaImg} alt="JSON Schema logo" />
        </a>
        <a href="https://graphql.org/" title="GraphQL">
          <img src={graphQLImg} alt="GraphQL logo" />
        </a>
        <a href="https://json-ld.org/" title="JSON-LD">
          <img src={jsonLDImg} alt="JSON-LD logo" />
        </a>
        <a href="https://github.com/OAI/OpenAPI-Specification" title="OpenAPI Specification">
          <img src={openApiImg} alt="Open API Specification logo" />
        </a>
        <a href="https://avro.apache.org/" title="Apache Kafka Avro Schema">
          <img src={avroImg} alt="Apache Avro logo" />
        </a>        
      </Container>
    </div>
  }
};

export default HomePage