import React, { Component } from "react";
import { inject } from 'mobx-react';
import { Redirect } from "react-router";

@inject("auth")
class DashboardPage extends Component {
  render() {
    return <Redirect to={this.props.auth.user.username} />
  }
}

export default DashboardPage;
