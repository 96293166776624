import React from 'react'

export const UseAPI = (props) => {
  return(
    <React.Fragment>
      <h5>Retrieve JSON Schema</h5>
      <pre>{
        `GET ${props.url}
Accept: application/schema+json`}
      </pre>
      <h5>Retrieve YAML JSON Schema</h5>
      <pre>{
        `GET ${props.url}
Accept: application/schema+yaml`}
      </pre>
    </React.Fragment>
  )
}

export const CLIInstallLocally = (props) => {
  return(
    <React.Fragment>
      <h5>Install Locally</h5>
      <pre>{
        `$ npm install -g @supermodel/cli
$ supermodel install ${props.url}`}
      </pre>
    </React.Fragment>
  )
}

export const CLIGenerate = (props) => {
  return(
    <React.Fragment>
      <h5>Generate GraphQL Schema</h5>
      <pre>{
        `$ supermodel schema graphql ./supermodel${props.entityPath}`}
      </pre>
      <h5>Generate OpenAPI Specification</h5>
      <pre>{
        `$ supermodel schema oas2 ./supermodel${props.entityPath}`}
      </pre>
    </React.Fragment>
  )
}

export const CLIIntro = (props) => {
  return(
    <React.Fragment>
      <hr />
      <p>
        Refer to <a href="https://github.com/supermodel/supermodel" target="_blank" rel="noopener noreferrer">Supermodel CLI README</a> for more details about the Supermodel command line interface.
      </p>
    </React.Fragment>
  )
}

export const TokensUsage = () => {
  return(
    <React.Fragment>
      <h5>API Tokens &amp; CLI</h5>
      <p>
        You can use a supermodel API token with the Supermodel command line tool instead of loging with your credentials.
      </p>
      <pre>{
        `$ export SUPERMODEL_TOKEN=<your token>
$ supermodel push`}
      </pre>
    </React.Fragment>
  )
}
