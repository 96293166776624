import { Position, Toaster, Intent } from "@blueprintjs/core";

const DEFAULT_TIMEOUT = 15 * 1000;

/** Singleton toaster instance. Create separate instances for different options. */
const Notifications = Toaster.create({
  className: "recipe-toaster",
  position: Position.TOP_RIGHT,
});

const SHORTCUTS = [
  ['success', Intent.SUCCESS],
  ['danger', Intent.DANGER]
]

SHORTCUTS.forEach(([functionName, intent]) => {
  Notifications[functionName] = function(options) {
    if (typeof (options) === 'string') {
      options = { ...arguments[1], message: options }
    }

    return Notifications.show({
      timeout: DEFAULT_TIMEOUT,
      ...options,
      intent
    })
  }
})

export default Notifications
