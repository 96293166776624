import React from 'react';
import PropTypes from 'prop-types'
import Title from 'react-document-title'

import Disclaimer from '../Content/Legal/Disclaimer'
import Privacy from '../Content/Legal/Privacy'
import Terms from '../Content/Legal/Terms'
import HowItWorks from '../Content/HowItWorks'

export default class ContentPage extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['Terms', 'Privacy', 'Disclaimer', 'HowItWorks']).isRequired
  }

  render() {
    const Content = {
      Disclaimer,
      Privacy,
      Terms,
      HowItWorks
    }[this.props.type]

    const title = {
      HowItWorks: 'How Supermodel Works'
    }[this.props.type] || `${this.props.type} - Supermodel`

    return (
      <Title title={title}>
        <Content />
      </Title>
    )
  }
};
