import { computed } from 'mobx';
import { fromPromise } from 'mobx-utils';

export default class Breadcrumb {
  fetch = null

  title = null
  type = null
  url = null

  constructor(data, fetch) {
    Object.assign(this, data)
    this.fetch = fetch
  }

  get parentUrl() {
    return this.url.replace(/\/[^/]+$/,'')
  }

  @computed
  get neighbours() {
    // console.log("Getting neigh...")

    if (!this.neighboursPromise) {
      this.loadNeighbours()
    }

    return this.neighboursPromise.case({
      pending: () => [{ title: 'Loading ...', disabled: true }],
      rejected: () => [{ title: 'Can\'t load entities', disabled: true }],
      fulfilled: neighbours => {
        return neighbours.length === 1
                ? [{ title: 'No entities', disabled: true }] :
                neighbours.map(n => ({...n, disabled: n.url === this.url }))
      }
    })
  }

  loadNeighbours = () => {
    // console.log("Loading neigh...")
    const request = this.fetch(this.parentUrl).then(response => {
      if (response.ok) {
        return response.json()
      }

      switch (response.status) {
        case 403:
          return Promise.reject('forbidden')
        case 404:
          return Promise.reject('notFound')
        default:
          return Promise.reject('error')
      }
    }).then(data => data.nested_entities)

    this.neighboursPromise = fromPromise(request)
  }
}
