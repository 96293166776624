import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@blueprintjs/core'
import Link from '../Link'
import { observer } from 'mobx-react'
import GenericList from '../GenericList'

@observer class EntityList extends Component {
  static propTypes = {
    controls: PropTypes.node,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    itemControls: PropTypes.func,
    showItemId: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired
  }

  static defaultProps = {
    showItemId: false
  }

  render() {
    return <GenericList
      {...this.props}
      itemRenderer={this.renderItem}
      itemControlsRenderer={this.props.itemControls}
    />
  }

  renderItem = (entity) => {
    const { title, url, type } = entity
    const { showItemId } = this.props

    return <h5>
      <Icon icon={type === 'Layer' ? 'layers' : 'widget' } iconSize={18}/>&nbsp;&nbsp;
      <Link to={url}>
        {title}
        {showItemId && <small>{url}</small>}
      </Link>
    </h5>
  }
}

export default EntityList