import React from 'react';
import RecoveryLink from '../RecoveryLink'

const NotFoundPage = () => (
  <div className="state-page">
    <h1>
      <span role="img" aria-label="empty-icon">📭</span> Page does not exist
    </h1>
    <RecoveryLink onClick={() => window.history.back()}>Go back</RecoveryLink>
  </div>
);

export default NotFoundPage;
