import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Title from 'react-document-title'
import { Flex, Box } from 'reflexbox'
import { withRouter } from 'react-router'

import NavBar from '../NavBar'
import Footer from '../Footer'

import './Layout.css'

@withRouter class Layout extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired
  }

  state = {
    error: null,
    info: null
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  componentDidCatch(error, info) {
    if (window.Rollbar) {
      window.Rollbar.error(error)
    }
    this.setState({ error, info });
  }

  render() {
    return (
      <Title title="Supermodel">
        <Flex column justify="space-between" className="layout">
          <Box>
            <NavBar />
          </Box>
          {this.state.error === null ? this.props.children : this.renderError()}
          <Box>
            <Footer />
          </Box>
        </Flex>
      </Title>
    );
  }

  renderError() {
    return (
      <React.Fragment>
        <h2>Error occured.</h2><br />
        {this.renderDetailedError()}
      </React.Fragment>
    )
  }

  renderDetailedError() {
    if(process.env.NODE_ENV === 'production') {
      return null
    }

    const { error, info } = this.state;

    return (
      <React.Fragment>
        <b>Stacktrace</b>
        <pre>{error.stack}</pre>
        <b>Component Stacktrace</b>
        <pre>{info.componentStack}</pre>
      </React.Fragment>
    )
  }
}

export default Layout;
