import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { isExternalUrl, stripHost } from 'lib/url'

export default function Link({ to, href, children, ...props}) {
  const url = to && to.length ? to : href

  if (isExternalUrl(url)) {
    return <a href={to} {...props}>{children}</a>
  }

  return <RouterLink to={stripHost(url)} {...props}>{children}</RouterLink>
}
