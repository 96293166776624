import React from 'react';

import './HowItWorks.css'
import createWebImg from './create_web.png'
import editWebImg from './edit_web.png'
import editEditorImg from './edit_cli.png'
import publishWebImg from './publish_web.png'
import referenceWebImg from './reference_web.png'
import shareImg from './share.png'
import reviewImg from './review.png'
import referenceShortImg from './reference_short.png'

const HowItWorks = () => <div className="how-it-works">
  <h1>How Supermodel Works</h1>

  <div className="how-it-works__step">
    <h2>What are you modeling today?</h2>
    <p>
      The Supermodel is a collaborative data-modeling tool and a data model registry. Supermodel uses JSON Schema in YAML format for its models. You can author the models directly at supermodel.io or locally on your computer and then push it to supermodel.io using the Supermodel command line tool (CLI).
    </p>
    <p>
      Once you publish a model, anybody can reference and reuse it. You can also use Supermodel to search and reuse other models including the vast collection of models based on schema.org and GS1 vocabularies. 
    </p>
    <p>
      When you find a model that you would like to use, you can refer it, consume it directly as JSON Schema or use the Supermodel CLI tool to convert it to various other formats such as OpenAPI Specification, Apache Avro (Kafka) Schema, or GraphQL schema.
    </p>
    <p>
      The main objectives of Supermodel are to promote modern-day data modeling, manage complex domains, improve communication, and to enable discovery and reuse of data models. 
    </p>
  </div>
  <div className="how-it-works__step">
    <h2>1. Identify concepts</h2>
    <p>
      What should your model be? Explore the concepts in you application domain and identify those you want to model.
      <br />
      Consider if it's a model critical for your application or it just supports your core domain.
    </p>
  </div>

  <div className="how-it-works__step">
    <h2>2. Explore existing models</h2>
    <p>
      <a href="https://supermodel.io/app/search?q=User">Search supermodel.io</a> for existing models and properties that can be reused.
      <br />
      Make sure to check:
    </p>
    <ul>
      <li>your models</li>
      <li><a href="https://supermodel.io/schemaorg">Schema.org models</a></li>
      <li><a href="https://supermodel.io/gs1">GS1 models</a></li>
      <li>ISO codes</li>
    </ul>
  </div>

  <div className="how-it-works__step">
    <h2>3. Create the model</h2>
    <p>
      You can either create your model either using the <a href="https://supermodel.io/">supermodel.io</a> web application or from the command line with <a href="https://github.com/supermodel/supermodel">Supermodel CLI</a>.
      Organize the models in layers–subdomains based on your team needs, application layers (database, API, UI, etc.)
    </p>
    <div className="how-it-works__comparison">
      <div>
        <h3>a. using web</h3>
        <img src={createWebImg} alt="Create model from web interface" />
      </div>
      <div>
        <h3>b. using CLI</h3>
        <pre>{`$ npm install -g @supermodel/cli
$ supermodel login`}
        </pre>
        <pre>{`$ supermodel init
$ cd supermodel/<supermodelio-username>
$ supermodel model create User`}
        </pre>
      </div>
    </div>
  </div>

  <div className="how-it-works__step">
    <h2>4. Author the model</h2>
    <p>
    Author the model in JSON Schema YAML format
    </p>
    <div className="how-it-works__comparison">
      <div>
        <h3>a. using web</h3>
        <img src={editWebImg} alt="edit model in web editor" />
      </div>
      <div>
        <h3>b. using CLI</h3>
        <p>
          Edit the model in your favorite editor
        </p>
        <img src={editEditorImg} alt="edit model in your favorite editor"  style={{padding: '0px'}}/>
      </div>
    </div>
    <h3>c. using Examples</h3>
    <p>
      If you have existing data examples, you can fast-track your model authoring using JSON to JSON Schema conversion tools like <a href="https://jsonschema.net/" target="_blank" rel="noopener noreferrer">jsonschema.net</a>.
    </p>
  </div>

  <div className="how-it-works__step">
    <h2>5. Reference freely</h2>
    <p>
      You can reference any other models or even model properties freely. Search, reference, reuse. No need to reinvent the models that somebody else already created.
    </p>
    <img src={referenceWebImg} alt="reference other models"/>
  </div>

  <div className="how-it-works__step">
    <h2>6. Publish</h2>
    <p>
      Publish your model
    </p>
    <div className="how-it-works__comparison">
      <div>
        <h3>a. using web</h3>
        <img src={publishWebImg} alt="Publish model from web interface" />
      </div>
      <div>
        <h3>b. using CLI</h3>
        <pre>
          {`$ supermodel push`}
        </pre>
      </div>
    </div>
  </div>

  <div className="how-it-works__step">
    <h2>7. Review</h2>
    <p>
      Review your model with peers, stakeholders or consumer. Is the model definition clear to everybody? Does it have the expected values and functionality?
    </p>
    <img src={reviewImg} alt="review the model"/>
  </div>

  <div className="how-it-works__step">
    <h2>8. Use the model</h2>

    <div className="how-it-works__substep">
      <h3>a. Share</h3>
      <p>
        Any model can be freely shared and referenced using its URL to be discussed and review with others.
      </p>
      <img src={shareImg} alt="share the model"/>
    </div>

    <div className="how-it-works__substep">
      <h3>b. Reference it anywhere</h3>
      <p>
        Similarly to sharing, a model can be freely referenced by other models.
      </p>
      <img src={referenceShortImg} alt="share the model"/>
    </div>

    <div className="how-it-works__substep">
      <h3>c. Use Supermodel API</h3>
      <p>
        Of course, supermodel.io comes with API! You can fetch your models and runtime in any application via the HTTP API and content negotiation.
      </p>
      <pre>{`GET https://supermodel.io/z+1/User
Accept: application/schema+json`}
      </pre>
      <pre>{`GET https://supermodel.io/z+1/User
Accept: application/schema+yaml`}
      </pre>
    </div>

    <div className="how-it-works__substep">
      <h3>d. Use with OpenAPI Specification</h3>
      <p>
        A Model or entire layer can be converted to OpenAPI Specification format for the use in your API definition.
      </p>
      <pre>{`$ supermodel schema oas2 <path-to-model>
`}
      </pre>
    </div>

    <div className="how-it-works__substep">
      <h3>e. Use with GraphQL</h3>
      <p>
        A Model or entire layer can be converted to GraphQL Schema use in your GraphQL API definition.
      </p>
      <pre>{`$ supermodel schema graphql <path-to-model>
`}
      </pre>
    </div>

    <div className="how-it-works__substep">
      <h3>f. Use with Apache Kafka / Avro</h3>
      <p>
        A Model or entire layer can be converted to Kafka Avro Schema use in as a Kafka topic.
      </p>
      <pre>{`$ supermodel schema avro <path-to-model>
`}
      </pre>
    </div>


    <div className="how-it-works__substep">
      <h3>g. Validate JSON instances</h3>
      <p>
        You can use a model to validate a JSON instance against it, to see whether it is compliant with the model schema.
      </p>
      <pre>{`$ supermodel validate <json> <path-to-model>
`}
      </pre>
    </div>

    <div className="how-it-works__substep">
      <h3>h. Validate application input</h3>
      Use supermodel model fixtures or runtime API to perform JSON validation in your application.
    </div>

    <div className="how-it-works__substep">
      <h3>i. Generate user interfaces</h3>
      Use supermodel model fixtures or runtime API to generate input forms on the fly using tools like <a href="https://jsonforms.io/" target="_blank" rel="noopener noreferrer">JSONForms</a>.
    </div>

    <div className="how-it-works__substep">
      <h3>j. Use it your way</h3>
      Generate documentation, SDKs, TypeScript interfaces and many others thanks to the rich <a href="https://json-schema.org/implementations.html"  target="_blank" rel="noopener noreferrer">JSON Schema ecosystem &amp; tooling</a>.
    </div>

  </div>

</div>

export default HowItWorks
