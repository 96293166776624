import React from 'react'
import PropTypes from 'prop-types'
import { Card } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'
import cn from 'classnames'

import './ContentBox.css'

function ContentBox({ children, className, controls, title }) {
  let controlElement

  if (controls) {
    controlElement = <Box>{controls}</Box>
  }

  return (
    <Card className={cn("content-box", className)}>
      <Flex justify="space-between" pt={10} pb={10} pl={20} pr={20} align="center">
        <Box>
          <h3>{title}</h3>
        </Box>
        {controlElement}
      </Flex>
      {children}
    </Card>
  )
}

ContentBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  controls: PropTypes.node,
  title: PropTypes.string.isRequired
}

export default ContentBox
