import React from 'react';
import RecoveryLink from '../RecoveryLink'

const ErrorPage = () => (
  <div className="state-page">
    <h1>
      <span role="img" aria-label="bug-icon">🐛</span> Something went wrong
    </h1>
    <RecoveryLink onClick={() => window.location.reload(true)}>Try reload page</RecoveryLink>
    {" "}or{" "}
    <RecoveryLink onClick={() => window.history.back()}>Go back</RecoveryLink>
  </div>
);

export default ErrorPage;
