import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Classes, Hotkey, Hotkeys, HotkeysTarget, Icon } from '@blueprintjs/core';
import { withRouter } from 'react-router'
import Button from '../Button';
import cx from 'classnames'

import './styles.css'

@withRouter
@HotkeysTarget
class SearchField extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    large: PropTypes.bool,
    showSubmit: PropTypes.bool
  }

  static defaultProps = {
    large: false,
    showSubmit: false
  }

  state = {
    error: false
  }

  handleSearch = (ev) => {
    ev.preventDefault()
    const query = this.searchEl.value
    if (query.length > 1) {
      this.setState({ error: false })
      this.props.history.push({
        pathname: '/app/search',
        search: `?q=${this.searchEl.value}`
      })
    } else {
      this.setState({ error: true })
    }
  }

  handleFocus = () => {
    this.searchEl.focus()
  }

  handleBlur = () => {
    this.setState({ error: false })
  }

  render() {
    return (
      <form onSubmit={this.handleSearch} className="search-form">
        <label className={cx('search-form__input', Classes.INPUT_GROUP, { [Classes.LARGE]: this.props.large })}>
          <Icon icon="search" iconSize={Icon.SIZE_INHERIT}/>
          <input
            ref={el => this.searchEl = el}
            tabIndex={1}
            className={cx(Classes.INPUT, { [Classes.INTENT_DANGER]: this.state.error })}
            type="search"
            placeholder="Search models"
            dir="auto"
            onBlur={this.handleBlur}
          />
        </label>
        {this.props.showSubmit && <Button green className="search-form__submit" type="submit">Search</Button>}
      </form>
    )
  }

  // NOTE: move somewhere to top level globally when there will be more hotkeys
  renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
            global={true}
            combo="shift + s"
            label="Start searching"
            onKeyDown={this.handleFocus}
            preventDefault
        />
      </Hotkeys>
    )
  }
}

export default SearchField