import React from 'react';
import { Classes, Intent, Spinner } from '@blueprintjs/core'

export default class LoadingPage extends React.Component {

  static defaultProps = {
    delay: 150
  }

  constructor(props) {
    super()

    this.state = {
      waiting: props.delay && props.delay > 0
    }
  }

  componentDidMount() {
    if (this.state.waiting) {
      this.timeout = setTimeout(() => this.setState({ waiting: false }), this.props.delay)
    }
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout)
  }

  render() {
    if (this.state.waiting) {
      return null
    }

    return (
      <div className="state-page">
        <h1>Loading</h1>
        <Spinner large={true} intent={Intent.PRIMARY} className={Classes.LARGE} />
      </div>
    )
  }
}
