import React from 'react';
import RecoveryLink from '../RecoveryLink'

const ForbiddenPage = () => (
  <div className="state-page">
    <h1>
      <span role="img" aria-label="lock-icon">🔒</span> You don't have permissions to view this page
    </h1>
    <RecoveryLink onClick={() => window.history.back()}>Go back</RecoveryLink>
  </div>
);

export default ForbiddenPage;
