import React from 'react';
import PropTypes from 'prop-types'

import ErrorPage from './ErrorPage'
import ForbiddenPage from './ForbiddenPage'
import NotFoundPage from './NotFoundPage'
import LoadingPage from './LoadingPage'

const StatePage = ({ type }) => {
  switch (type) {
    case 'loading':
      return <LoadingPage />
    case 'notFound':
      return <NotFoundPage />
    case 'forbidden':
      return <ForbiddenPage />
    case 'error':
      return <ErrorPage />
    default:
      throw new Error('Missin \'type\' prop')
  }
}

StatePage.propTypes = {
  type: PropTypes.oneOf(['notFound', 'error', 'forbidden', 'loading'])
}

export default StatePage;
