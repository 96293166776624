import React, { Component } from 'react';
// import { Flex, Box } from 'reflexbox'

import Link from '../Link';

import spectrum from './spectrum.svg';
import twitter from './twitter.svg';
import github from './github.svg';

import './Footer.css'

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="footer-links">
            <span className="footer-link">
              <Link to="/">supermodel.io</Link>
            </span>
            <span className="footer-spacer" />
            <span className="footer-link">Copyright ©2020 Supermodel Company.</span>
          </div>

          <hr />

          <div className="footer-links footer-contacts">
            <Link className="footer-link" to="https://twitter.com/supermodelio">
              <img src={twitter}  alt="badge" />
              <span>@supermodelio</span>
            </Link>
            <Link className="footer-link" to="https://spectrum.chat/supermodel">
              <img src={spectrum} style={{maxHeight: '1.1rem'}} alt="badge" />
            </Link>
            <Link className="footer-link" to="https://github.com/supermodel/supermodel">
              <img src={github}  alt="badge" />
              <span>Supermodel CLI</span>
            </Link>
            <span className="footer-spacer" />
            <a className="footer-link" href="mailto:hello@supermodel.io">hello@supermodel.io</a>
          </div>

          <hr className="footer-mobile-spacer" />

          <div className="footer-links footer-legal">
            <span className="footer-spacer" />
            <Link className="footer-link" to="/how-it-works">How Supermodel Works</Link>
            <Link className="footer-link" to="/legal/disclaimer">Disclaimer</Link>
            <Link className="footer-link" to="/legal/privacy">Privacy policy</Link>
            <Link className="footer-link" to="/legal/terms">Terms of Use</Link>
          </div>
        </div>
      </div>
    );
  }
}
